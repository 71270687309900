import React, { useEffect, useState } from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import axios from "axios";
import RenderPosts from "./components/renderPosts";
import RenderLoading from "./components/loading";

// Importamos el idioma español para dayjs y lo activamos
require("dayjs/locale/es");
dayjs.locale("es");

// Activamos plugin de dayjs para mostrar la fecha en formato relativo
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Component = ({ pageContext: { author } }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            const toastId = toast.loading("Cargando datos...", {
                position: "bottom-center",
            });

            const rawData = await getAuthor({ author: author.slug, page: 1 });
            setData(rawData);

            setLoading(false);
            toast.dismiss(toastId);
        }

        fetchData();
    }, [author.slug]);

    return (
        <>
            <RenderSeo author={author} />
            <Layout>
                <RenderBanner author={author} />
                {loading && <RenderLoading />}
                {data && (
                    <div className="container py-20 mx-auto">
                        <RenderPosts
                            loading={loading}
                            data={data}
                            paginationBase={`/blog/author/${author.slug}`}
                            firstExpanded={false}
                        />
                    </div>
                )}
            </Layout>
        </>
    );
};

const getAuthor = async ({ author, page = 1 }) => {
    try {
        const url = `/.netlify/functions/posts`;
        const params = {
            author,
            page: page || 1,
        };

        const options = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const { data } = await axios.post(url, params, options);

        return data?.posts;
    } catch (err) {
        console.error(err);
        return null;
    }
};

const RenderSeo = ({ author }) => {
    const { name, bio, avatar } = author;
    return <Seo title={name} description={bio} author={name} image={avatar} />;
};

const RenderAuthor = ({ author }) => {
    return (
        <section className={styles.authorSection.container}>
            <img src={author.avatar} alt={author.name} className={styles.authorSection.avatar} />

            <div>
                <h1 className={styles.authorSection.title}>{author.name}</h1>
                <h2 className={styles.authorSection.position}>{author.position}</h2>
                <p className={styles.authorSection.bio}>{author.bio}</p>
            </div>
        </section>
    );
};

const RenderBanner = ({ author }) => {
    return (
        <section
            className={styles.banner.container}
            // style={{ height: `calc(100vh - 165px)` }}
        >
            <img
                src={author.banner}
                alt={author.name}
                className={styles.banner.image}
                style={{ filter: "blur(4px)" }}
            />

            <div className={styles.banner.overlay} />

            <div className="absolute bottom-0 w-full pb-8 z-20 jus">
                <div className="mx-auto container">
                    <RenderAuthor author={author} />
                </div>
            </div>
        </section>
    );
};

const styles = {
    banner: {
        container: "h-116 md:h-96 bg-gray-300 relative overflow-hidden",
        image: "object-cover w-full h-full z-0 absolute top-0 left-0",
        overlay: "w-full h-full top-0 left-0 absolute z-10 bg-black bg-opacity-25",
    },
    authorSection: {
        container:
            "flex flex-col md:flex-row gap-5 md:gap-10 md:items-center justify-center w-full md:justify-start",
        title: "font-bold text-2xl lg:text-3xl text-white text-center md:text-left",
        bio: "text-white text-center md:text-left",
        position:
            "text-xs lg:text-sm uppercase text-gray-200 font-semibold opacity-80 text-center md:text-left",
        avatar: "object-cover w-32 h-32 rounded-full flex-shrink-0 text-sm lg:text-base truncate-lines-2 lg:truncate-lines-4 mx-auto md:mx-0",
    },
};

export default Component;
